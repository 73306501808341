import { useEffect, useRef, useState } from "preact/hooks";
import { RusheeCard } from "./card";
import { Settings } from "../../views/settings";
import { ACTIVES } from "../../data/actives";
import { route } from "preact-router";

// // Dummy rushees list
// export const RUSHEES = [
//     { photo: "https://i.imgur.com/VShn15G.jpg", level: 10, name: "Matt S." },
//     { photo: "https://i.imgur.com/fcsaEgz.jpg", level: 10, name: "Nick D." },
//     { photo: "https://imgur.com/3SChj5E.jpg", level: 8, name: "Will R." },
//     { photo: "https://imgur.com/AfcMZU0.jpg", level: 4, name: "Chris H." },
//     { photo: "https://imgur.com/AfcMZU0.jpg", level: 4, name: "Chris H." },
//     { photo: "https://imgur.com/AfcMZU0.jpg", level: 4, name: "Chris H." },
//     { photo: "https://imgur.com/AfcMZU0.jpg", level: 4, name: "Chris H." },
//     { photo: "https://imgur.com/AfcMZU0.jpg", level: 4, name: "Chris H." },
//     { photo: "https://imgur.com/AfcMZU0.jpg", level: 4, name: "Chris H." },
//     { photo: "https://imgur.com/AfcMZU0.jpg", level: 4, name: "Chris H." },
//     { photo: "https://imgur.com/AfcMZU0.jpg", level: 4, name: "Chris H." },
//     { photo: "https://i.imgur.com/gC6QAle.jpg", level: 3, name: "Ellington Hemphill" },
//     { photo: "https://i.imgur.com/rbE3Nmb.png", level: 1, name: "Rushil" },
// ];

/**
 * Component for detailed information about a single rushee.
 */
export function RusheeInfo({ id }) {
    // TODO: fetch this from server
    const [info, setInfo] = useState({});
    const [comments, setComments] = useState([]);
    const [readonly, setReadonly] = useState(true);

    const formRef = useRef();
    const uploadRef = useRef();

    const firstName = info.name?.split(" ")[0];

    // Get information and comments about this rushee
    useEffect(() => {
        fetch(`/api/rushee.cgi?id=${id}&whoami=${Settings.Kerb()}`)
            .then((res) => res.json())
            .then((res) => {
                setInfo(res.info);
                setComments(res.comments
                    .map((c) => ({ ...c, timestamp: new Date(c.timestamp) }))
                    .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
                );
            });
    }, [id]);

    // FLUSHED
    useEffect(() => {
        if (!info.level || info.level > 0) {
            return;
        }
        console.log("FLUSHED");
        confetti({
            spread: 360,
            ticks: 50,
            gravity: 0.5,
            decay: 0.94,
            startVelocity: 30,
            particleCount: 100,
            scalar: 3,
            shapes: ["image"],
            shapeOptions: {
              image: [{
                  src: "/assets/rho/emotes/finger.gif",
                  width: 184,
                  height: 188,
                }],
            },
        });
        confetti({
            spread: 0,
            ticks: 50,
            gravity: 0.1,
            decay: 0.2,
            startVelocity: 0,
            particleCount: 1,
            scalar: 1,
            shapes: ["image"],
            shapeOptions: {
                image: [{
                    src: "/assets/rho/emotes/finger.gif",
                    width: 1840,
                    height: 1880,
                }],
            },
        });
    }, [info]);

    // Edit information about this rushee
    function edit(e) {
        e?.preventDefault();

        // Don't allow editing until the brother is loaded
        if (Object.getOwnPropertyNames(info).length === 0) {
            return;
        }
        
        const changed = {};
        for (const [key, val] of new FormData(formRef.current).entries()) {
            if (info[key] === val) {
                continue;
            }
            info[key] = changed[key] = val;
        }
        fetch("/api/rushee.cgi", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                op: "edit",
                id,
                changed,
                whoami: Settings.Kerb(),
            }),
        })
        .then((res) => res.text())
        .then((_) => location.reload());
    }

    // Post a new comment on this rushee's page
    function comment(e) {
        e.preventDefault();

        const whoami = Settings.Kerb();
        const value = new FormData(e.target).get("comment");

        if (value.length === 0) {
            return;
        }

        fetch("/api/rushee.cgi", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                op: "comment",
                id,
                whoami,
                value,
            }),
        });
        e.target.reset();
        setComments([{ whoami, value, timestamp: new Date() }, ...comments]);
    }

    async function uploadPicture() {
        const MAX_SIZE = 400;
        
        const file = uploadRef.current.files[0];
        if (!file) {
            return;
        }
        // https://stackoverflow.com/a/57272491
        const base64 = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                // resolve(reader.result)
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > MAX_SIZE) {
                            height *= MAX_SIZE / width;
                            width = MAX_SIZE;
                        }
                    } else {
                        if (height > MAX_SIZE) {
                            width *= MAX_SIZE / height;
                            height = MAX_SIZE;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext("2d").drawImage(img, 0, 0, width, height);

                    resolve(canvas.toDataURL("image/jpeg"));
                }
                img.src = reader.result;
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
        
        fetch("/api/rushee.cgi", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                op: "upload-picture",
                id,
                base64,
            }),
        }).then(() => {
            // Save before reloading the page
            edit(null);
        })
    }

    function flush() {
        fetch("/api/rushee.cgi", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                op: "flush",
                id,
            }),
        })
        .then((res) => res.text())
        .then((_) => location.reload());
    }

    return (
        <div class="rushee-info" onClick={(e) => e.target === e.currentTarget && route("/rho")}>
            <div id="container">
                <input id="upload" type="file" accept="image/*" style="display: none" onChange={uploadPicture} ref={uploadRef}/>
                <form method="post" action="/api/rushee.cgi" onsubmit={edit} ref={formRef}>
                    <div id="top-bar">
                        <input id="name" type="text" name="name" placeholder="Name" value={info.name ?? "Loading..."} readonly={readonly}/>
                        <a href="/rho" class="cr-button red" id="close">X</a>
                    </div>
                    <div id="overview">
                        <div id="card">
                            <RusheeCard name="" level={info.level} picture={info.picture}/>
                            {!readonly && <button id="upload" onClick={(e) => e.preventDefault() || uploadRef.current.click()}>
                                Upload Picture
                            </button>}
                        </div>
                        <textarea id="description" name="description" placeholder={`Notes about ${info.name}...`} readonly={readonly}>
                            {info.description ?? ""}
                        </textarea>
                    </div>
                    <div id="statistics">
                        <GridItem name="Hometown" icon="people_count.png">
                            <input id="hometown" type="text" name="hometown" placeholder="???" value={info.hometown} readonly={readonly}/>
                        </GridItem>
                        <GridItem name="Major" icon="people_count.png">
                            <input id="major" type="text" name="major" placeholder="???" value={info.major} readonly={readonly}/>
                        </GridItem>
                        <GridItem name="Phone Number" icon="people_count.png">
                            <input id="phone" type="tel" name="phone" placeholder="???" value={info.phone} readonly={readonly}/>
                        </GridItem>
                        <GridItem name="Main Contact" icon="people_count.png">
                            <select name="contact" id="contact" disabled={readonly}>
                                {ACTIVES.map((kerb) => (
                                    <option value={kerb} selected={kerb === info.contact}>{kerb}</option>
                                ))}
                                <option value="" selected={!info.contact} disabled hidden>???</option>
                            </select>
                        </GridItem>
                        <GridItem name="Last Contacted" icon="people_count.png">
                            {(!info.last_contacted && readonly)
                                ? <div>Never</div>
                                : <input id="last_contacted" type="date" name="last_contacted" value={info.last_contacted?.split("T")[0]} readonly={readonly}/>
                            }
                        </GridItem>
                        <GridItem name="Me, personally" icon="people_count.png">
                            <select name="meeting" id="meeting" disabled={readonly}>
                                <option value="0" selected={+info.meeting === 0}>I have not met {firstName}</option>
                                <option value="1" selected={+info.meeting === 1}>I have met {firstName}</option>
                            </select>
                        </GridItem>
                        <GridItem name="Feeling" icon="people_count.png">
                            <select name="vote" id="vote" disabled={readonly}>
                                <option value="-1" selected={+info.vote === -1}>I dislike {firstName}</option>
                                <option value="0" selected={+info.vote === 0}>I lack strong feelings about {firstName}</option>
                                <option value="1" selected={+info.vote === 1}>I like {firstName}</option>
                            </select>
                        </GridItem>
                        <GridItem name="Steak & Lobster" icon="people_count.png">
                            <select name="steak_and_lobster" id="steak_and_lobster" disabled={readonly}>
                                <option value="0" selected={+info.steak_and_lobster === 0}>Not invited to S&L</option>
                                <option value="1" selected={+info.steak_and_lobster === 1}>Invited to S&L</option>
                            </select>
                        </GridItem>
                        <GridItem name="Boat Dinner" icon="people_count.png">
                            <select name="boat_dinner" id="boat_dinner" disabled={readonly}>
                                <option value="0" selected={+info.boat_dinner === 0}>Not invited to boat dinner</option>
                                <option value="1" selected={+info.boat_dinner === 1}>Invited to boat dinner</option>
                            </select>
                        </GridItem>
                        <GridItem name="Bid" icon="people_count.png">
                            <select name="bid" id="bid" disabled={readonly}>
                                <option value="0" selected={+info.bid === 0}>Not invited to Bid Dinner™</option>
                                <option value="1" selected={+info.bid === 1}>Invited to Bid Dinner™</option>
                            </select>
                        </GridItem>
                        <GridItem name="Brothers Met" icon="people_count.png">
                            <span>
                                &nbsp;
                                {(() => {
                                    try {
                                        const met = JSON.parse(info.brothers_met);
                                        
                                        return Object.values(met).filter((x) => +x === 1).length;
                                    } catch {
                                        return 0;
                                    }
                                })()}
                                &nbsp;/ {ACTIVES.length}
                            </span>
                        </GridItem>
                        <GridItem name="Brothers who Like" icon="people_count.png">
                            <span>
                                &nbsp;
                                {(() => {
                                    try {
                                        const like = JSON.parse(info.brothers_like);
                                        
                                        return Object.values(like).filter((x) => +x === 1).length;
                                    } catch {
                                        return 0;
                                    }
                                })()}
                                &nbsp;/ {ACTIVES.length}
                            </span>
                        </GridItem>
                    </div>
                    {readonly && <button id="edit" type="button" onClick={() => setReadonly(false)} class="cr-button blue">Edit</button>}
                    {!readonly && <button id="save" type="submit" value="Save Changes" class="cr-button green">Save Changes</button>}
                    {!readonly && <button id="flush" type="button" onClick={() => flush()} class="cr-button red">Flush</button>}
                </form>
            </div>
            <form id="comments" method="post" action="/api/rushee.cgi" onsubmit={comment}>
                <div id="new-comment">
                    <textarea id="textbox" name="comment" placeholder={`Say something about ${firstName}...`}/>
                    <button id="post" type="submit" class="cr-button">Send</button>
                </div>
                {comments.map(({ value, whoami, timestamp }) => (
                    <ChatMessage sender={whoami} message={value} time={timestamp}/>
                ))}
            </form>
        </div>
    );
}

function GridItem({ icon, name, children, ...props }) {
    return (
        <div class="grid-item" {...props}>
            <img id="icon" src={`/assets/rho/icons/${icon}`}/>
            <div id="stat">
                <div id="name">{name}</div>
                <div id="value">{children}</div>
            </div>
        </div>
    );
}

function ChatMessage({ sender, message, time }) {
    return (
        <div class="chat-message">
            <div id="top">
                <span id="sender">{sender}</span>
                <span id="role">Brother</span>
            </div>
            <div id="middle">
                {message}
            </div>
            <div id="bottom">
                {formatDate(time)}
            </div>
        </div>
    );
}

function formatDate(date) {
    const dt = new Date() - date;
    
    if (Math.floor(dt / 1000) < 60) return 'just now';

    const dm = Math.floor(dt / 60000);
    if (dm < 60) {
        return `${dm}min ago`;
    }

    const dh = Math.floor(dt / (60000 * 60));
    if (dh < 24) {
        return `${dh}h ${dm % 60}min ago`;
    }

    return date.toISOString().split('T')[0];
}