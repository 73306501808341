import "../../styles/rho/rushee.css";

/**
 * Component for a card about a rushee. Level is a number from 1..=10 where 1 is unlikely to
 * receive a bid and 10 is bid.
 * 
 * @param {{
 *  name: string;
 *  level: number;
 *  photo: string;
 * }} props
 */
export function RusheeCard({ name, level, picture, steak_and_lobster, boat_dinner, bid, contact, last_contacted, ...props }) {
    // Rarity goes from common (no bid) to legendary (bid)
    // Elixir is more for aesthetics, but higher is better
    level = Math.round(level ?? 0);
    const [rarity, elixir] = (() => { switch (Math.max(Math.min(level, 10), 1)) {
        case 1:
        case 2:
        case 3:
            return ["common", level];
        case 4:
        case 5:
        case 6:
            return ["rare", level - 2];
        case 7:
        case 8:
        case 9:
            return ["epic", level - 2];
        case 10:
            return ["legendary", 9];
    }})();
    // Modify name to first name + last initial
    const [firstName, lastName] = (name ?? "").split(" ");

    let subtext = "";
    if (boat_dinner) {
        subtext += "🚢";
    }
    if (steak_and_lobster) {
        subtext += "🦞";
    }
    if (bid) {
        subtext += "💀";
    }

    const contactedDaysAgo = last_contacted ? Math.floor((new Date() - new Date(last_contacted)) / (60000 * 60 * 24)) : undefined;

    return (
        <div class="rushee-card-container" {...props}>
            <div class="rushee-card" rarity={rarity ?? "common"}>
                <div id="glow"/>
                <div id="photo" style={picture?.length > 0 && `background-image: url("${picture}")`}/>
                <div id="overlay"/>
                <div id="elixir">
                    {elixir ?? "?"}
                </div>
                <div id="name" style={`font-size: ${6 / Math.max(firstName.length, 6)}em`}>
                    {firstName} {lastName ? `${lastName[0]}.` : ""}
                </div>
                <div id="full-name" style={`font-size: ${6 / Math.max(firstName.length, 6)}em`}>
                    {name}
                </div>
                <div id="subtext">
                    {subtext}
                    {/* <span id="phone" class="material-symbols-rounded">&#xe0b0;</span> */}
                    {contact && (
                        <span id="phone">☎️&nbsp;<span id="num">{contactedDaysAgo}</span></span>
                    )}
                </div>
            </div>
        </div>
    );
}