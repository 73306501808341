import { route, Router } from "preact-router";
import { RusheeCard } from "../components/rho/card";
import { RusheeInfo, RUSHEES } from "../components/rho/rushee";
import { Fetch } from "../components/fetch";

import "../styles/rho/rho.css";
import { Settings, SettingsWarning } from "./settings";
import { useRef, useState } from "preact/hooks";

/**
 * Component for the rho (rush chair)'s internal website. This is its "main"
 * page and this subsection is used for rush jobs and adding rushee's contact
 * information (i.e. what the old rush website used to do).
 */
export function Rho() {
    const [orderBy, setOrderBy] = useState("level");

    function create() {
        fetch(`/api/rushee.cgi`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                op: "create",
                whoami: Settings.Kerb(),
            }),
        })
            .then((res) => res.text())
            .then((res) => res.trim())
            .then((id) => {
                route(`/rho/i/${id}`);
                location.reload();
            });
    }
    return (
        <>
            <SettingsWarning/>
            <div class="rho-container">
                <button class="cr-button" onClick={create}>Add Rushee</button>
                <select name="Order By" onChange={(e) => setOrderBy(e.target.value)}>
                    <option value="level">Likely to Pledge</option>
                    <option value="activity">Recent Edits/Comments</option>
                    <option value="contact">Not Contacted in a While</option>
                </select>
                <RusheeGrid sort={orderBy}/>
                <Router>
                    <div path="/rho" default/>
                    <RusheeInfo path="/rho/i/:id"/>
                </Router>
            </div>
        </>
    );
}

/**
 * Component for a list of rushees
 */
function RusheeGrid({ sort }) {
    return (
        <div id="cards-container">
            <Fetch url={`/api/rushee.cgi?sort=${sort ?? "level"}`} json>
                {(rushees) => rushees.map((rushee) => (
                    <a href={`/rho/i/${rushee.id}`}>
                        <RusheeCard {...rushee}/>
                    </a>
                ))}
            </Fetch>
        </div>
    );
}